import { HomePage } from "./components";

function App() {
  return (

    <HomePage />

  );
}

export default App;
